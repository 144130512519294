import React from "react";
import Project from "../../../projects.json";

function sunlakeBusinessCenter() {
  const project = Project.projects.find((project) => project.id === 29);

  const currentUrl = window.location.href;
  const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    currentUrl
  )}`;
  const liUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
    currentUrl
  )}`;

  function shareToFacebook() {
    window.open(fbUrl, "_blank", "width=600,height=400");
  }

  function shareToLinkedIn() {
    window.open(liUrl, "_blank", "width=600,height=400");
  }

  return (
    <>
      <div className="ttm-page-title ttm-bg clearfix">
        <div className="ttm-row-wrapper-bg-layer" />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="ttm-page-title-row-inner">
                <div className="page-title-heading">
                  <h2 className="title">{project.title}</h2>
                </div>
                <div className="breadcrumb-wrapper">
                  <span>
                    <a href="/projects">&nbsp; Projects</a>
                  </span>
                  <span>Project Details</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="site-main">
        <section className="ttm-row project-single-section clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ttm-pf-single-content-wrapper">
                  <div className="ttm-pf-single-content-wrapper-innerbox">
                    <div className="row" style={{ justifyContent: "center" }}>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="featured-icon-box style10 without-icon">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5 className="mb-0 fs-17">Location:</h5>
                            </div>
                            <div className="featured-desc">
                              <p>{project.location}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="featured-icon-box style10 without-icon">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5 className="mb-0 fs-17">Date Started:</h5>
                            </div>
                            <div className="featured-desc">
                              <p>{project.started}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="featured-icon-box style10 without-icon">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5 className="mb-0 fs-17">Date Completed:</h5>
                            </div>
                            <div className="featured-desc">
                              <p>{project.ended}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="margin_bottom50 margin_top50 portfolio-single text-center position-relative">
                            <ul className="social-icons list-inline">
                              <li>
                                <a
                                  id="facebook-share-btn"
                                  onClick={shareToFacebook}
                                >
                                  <i className="fa fa-facebook" />
                                </a>
                              </li>
                              <li>
                                <a
                                  id="linkedin-share-btn"
                                  onClick={shareToLinkedIn}
                                >
                                  <i className="fa fa-linkedin" />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 col-lg-12">
                          <div className="slider ttm_pf_image-wrapper">
                            <div>
                              <a
                                data-lightbox="sunlakeBusinessCenter-top"
                                href="/images/projects/sunlake business center/DJI_0028.jpg"
                              >
                                <img
                                  alt="Slide 1"
                                  className="img-fluid"
                                  src="/images/projects/sunlake business center/DJI_0028.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="sunlakeBusinessCenter-top"
                                href="/images/projects/sunlake business center/DJI_0034.jpg"
                              >
                                <img
                                  alt="Slide 2"
                                  className="img-fluid"
                                  src="/images/projects/sunlake business center/DJI_0034.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="sunlakeBusinessCenter-top"
                                href="/images/projects/sunlake business center/DJI_0039.jpg"
                              >
                                <img
                                  alt="Slide 3"
                                  className="img-fluid"
                                  src="/images/projects/sunlake business center/DJI_0039.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="sunlakeBusinessCenter-top"
                                href="/images/projects/sunlake business center/DJI_0046.jpg"
                              >
                                <img
                                  alt="Slide 4"
                                  className="img-fluid"
                                  src="/images/projects/sunlake business center/DJI_0046.jpg"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="ttm-pf-single-content-area">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="margin_top40">
                                <h2>{project.title}</h2>
                                <p>
                                  Sunlake Business Center, a joint venture
                                  between BRW and Frampton Construction, is
                                  nearing completion at the prime intersection
                                  of SR-54 and Sunlake Boulevard in Lutz, FL.{" "}
                                  <br />
                                  <br />
                                  Our involvement encompasses a comprehensive
                                  scope, vital to the project's success. We've
                                  undertaken site clearing and executed precise
                                  earthwork operations, ensuring optimal
                                  groundwork for this endeavor. Our expertise
                                  extends to the installation of storm drainage
                                  systems and underground utilities, as well as
                                  the implementation of robust fire protection
                                  systems. <br />
                                  <br />
                                  Elevating accessibility and visual appeal,
                                  we've crafted concrete sidewalks, curbs, and
                                  engaged in meticulous asphalt paving, with
                                  signage and striping contributing to enhanced
                                  functionality. Notably, our scope extends to
                                  both onsite and offsite improvements for two
                                  expansive warehouses, totaling an impressive
                                  361,000 sq ft.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-sm-6">
                              <div className="ttm_single_image-wrapper text-left padding_top15">
                                <a
                                  data-lightbox="sunlakeBusinessCenter-bottom"
                                  href="/images/projects/sunlake business center/DJI_0047.jpg"
                                >
                                  <img
                                    alt="single-img-10"
                                    className="img-fluid"
                                    src="/images/projects/sunlake business center/DJI_0047.jpg"
                                  />
                                </a>
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <div className="ttm_single_image-wrapper text-left padding_top15">
                                <a
                                  data-lightbox="sunlakeBusinessCenter-bottom"
                                  href="/images/projects/sunlake business center/DJI_0048.jpg"
                                >
                                  <img
                                    alt="single-img-11"
                                    className="img-fluid"
                                    src="/images/projects/sunlake business center/DJI_0048.jpg"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default sunlakeBusinessCenter;
