import React from "react";
import Project from "../../../projects.json";

function costcoClermont() {
  const project = Project.projects.find((project) => project.id === 15);

  const currentUrl = window.location.href;
  const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    currentUrl
  )}`;
  const liUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
    currentUrl
  )}`;

  function shareToFacebook() {
    window.open(fbUrl, "_blank", "width=600,height=400");
  }

  function shareToLinkedIn() {
    window.open(liUrl, "_blank", "width=600,height=400");
  }

  return (
    <>
      <div className="ttm-page-title ttm-bg clearfix">
        <div className="ttm-row-wrapper-bg-layer" />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="ttm-page-title-row-inner">
                <div className="page-title-heading">
                  <h2 className="title">{project.title}</h2>
                </div>
                <div className="breadcrumb-wrapper">
                  <span>
                    <a href="/projects">&nbsp; Projects</a>
                  </span>
                  <span>Project Details</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="site-main">
        <section className="ttm-row project-single-section clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ttm-pf-single-content-wrapper">
                  <div className="ttm-pf-single-content-wrapper-innerbox">
                    <div className="row" style={{ justifyContent: "center" }}>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="featured-icon-box style10 without-icon">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5 className="mb-0 fs-17">Location:</h5>
                            </div>
                            <div className="featured-desc">
                              <p>{project.location}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="featured-icon-box style10 without-icon">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5 className="mb-0 fs-17">Date Started:</h5>
                            </div>
                            <div className="featured-desc">
                              <p>{project.started}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="featured-icon-box style10 without-icon">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5 className="mb-0 fs-17">Date Completed:</h5>
                            </div>
                            <div className="featured-desc">
                              <p>{project.ended}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="margin_bottom50 margin_top50 portfolio-single text-center position-relative">
                            <ul className="social-icons list-inline">
                              <li>
                                <a
                                  id="facebook-share-btn"
                                  onClick={shareToFacebook}
                                >
                                  <i className="fa fa-facebook" />
                                </a>
                              </li>
                              <li>
                                <a
                                  id="linkedin-share-btn"
                                  onClick={shareToLinkedIn}
                                >
                                  <i className="fa fa-linkedin" />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 col-lg-12">
                          <div className="slider ttm_pf_image-wrapper">
                            <div>
                              <a
                                data-lightbox="costcoClermont-top"
                                href="/images/projects/costco clermont/DJI_0030.jpg"
                              >
                                <img
                                  alt="Slide 1"
                                  className="img-fluid"
                                  src="/images/projects/costco clermont/DJI_0030.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="costcoClermont-top"
                                href="/images/projects/costco clermont/DJI_0041.jpg"
                              >
                                <img
                                  alt="Slide 2"
                                  className="img-fluid"
                                  src="/images/projects/costco clermont/DJI_0041.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="costcoClermont-top"
                                href="/images/projects/costco clermont/DJI_0045.jpg"
                              >
                                <img
                                  alt="Slide 3"
                                  className="img-fluid"
                                  src="/images/projects/costco clermont/DJI_0045.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="costcoClermont-top"
                                href="/images/projects/costco clermont/DJI_0050.jpg"
                              >
                                <img
                                  alt="Slide 4"
                                  className="img-fluid"
                                  src="/images/projects/costco clermont/DJI_0050.jpg"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="ttm-pf-single-content-area">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="margin_top40">
                                <h2>{project.title}</h2>
                                <p>
                                  This expansive 20-acre site hosts a
                                  substantial 160,000 sq ft warehouse, complete
                                  with a fuel facility and an extensive parking
                                  area capable of accommodating over 800
                                  vehicles. <br />
                                  <br />
                                  BRW's involvement spans a range of critical
                                  tasks, from initial site clearing and precise
                                  earthwork operations to the installation of
                                  crucial storm drainage systems and essential
                                  underground utilities. Additionally, we're
                                  dedicated to enhancing safety with fire
                                  protection systems and promoting accessibility
                                  through the construction of concrete
                                  sidewalks, curbs, and asphalt paving. The
                                  finer details are addressed with meticulous
                                  signage and striping. <br />
                                  <br />
                                  Our dedicated crews have diligently persevered
                                  through Florida's challenging summer
                                  conditions, achieving significant milestones
                                  including mass grading, drainage setup, and
                                  utilities installation. Currently, they're
                                  focused on site concrete work, base
                                  establishment, and the meticulous installation
                                  of pavement.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-sm-6">
                              <div className="ttm_single_image-wrapper text-left padding_top15">
                                <a
                                  data-lightbox="costcoClermont-bottom"
                                  href="/images/projects/costco clermont/DJI_0018.jpg"
                                >
                                  <img
                                    alt="single-img-10"
                                    className="img-fluid"
                                    src="/images/projects/costco clermont/DJI_0018.jpg"
                                  />
                                </a>
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <div className="ttm_single_image-wrapper text-left padding_top15">
                                <a
                                  data-lightbox="costcoClermont-bottom"
                                  href="/images/projects/costco clermont/DJI_0021.jpg"
                                >
                                  <img
                                    alt="single-img-11"
                                    className="img-fluid"
                                    src="/images/projects/costco clermont/DJI_0021.jpg"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default costcoClermont;
