import { useState } from "react";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";

const Contact = () => {
  const [capVal, setCapVal] = useState(null);
  const sendEmail = (e) => {
    e.preventDefault();
    alert("Your email was sent!");
    window.location.reload();

    emailjs.sendForm(
      "service_8ailwss",
      "template_speslqg",
      e.target,
      "57rgEK2u3rLIDKJuO"
    );
  };
  return (
    <>
      <div className="ttm-page-title ttm-bg clearfix">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="ttm-page-title-row-inner">
                <div className="page-title-heading">
                  <h2 className="title">Contact Us</h2>
                </div>
                <div className="breadcrumb-wrapper">
                  <span>
                    <a title="Homepage" href="/">
                      <i className="fa fa-house" /> &nbsp; Home
                    </a>
                  </span>
                  <span>Contact</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="ttm-row form-section ttm-bgcolor-grey clearfix">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title title-style-center_text margin_bottom30">
                <div className="other-title-header">
                  <h3>GET IN TOUCH</h3>
                  <h2 className="title">
                    Reach Out to Us <span>Today!</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="contactForm">
              <div className=" ttm-bgcolor-white p-40 res-991-margin_right0 ">
                <form
                  id="contact_form"
                  onSubmit={sendEmail}
                  className="wrap-form contact_form padding_top15"
                  method="post"
                  action="#"
                >
                  <div className="row ttm-boxes-spacing-30px">
                    <div className="col-sm-6 ttm-box-col-wrapper">
                      <label>
                        <span className="text-input margin_bottom0">
                          <input
                            name="user_name"
                            type="text"
                            defaultValue=""
                            placeholder="Your Name*"
                            required="required"
                          />
                        </span>
                      </label>
                    </div>
                    <div className="col-sm-6 ttm-box-col-wrapper">
                      <label>
                        <span className="text-input margin_bottom0">
                          <input
                            name="user_phone"
                            type="text"
                            defaultValue=""
                            placeholder="Your Phone*"
                            required="required"
                          />
                        </span>
                      </label>
                    </div>
                    <div className="col-sm-6 ttm-box-col-wrapper">
                      <label>
                        <span className="text-input margin_bottom0">
                          <input
                            name="user_email"
                            type="text"
                            defaultValue=""
                            placeholder="Email Address*"
                            required="required"
                          />
                        </span>
                      </label>
                    </div>
                    <div className="col-sm-6 ttm-box-col-wrapper">
                      <label>
                        <span className="text-input margin_bottom0">
                          <input
                            name="subject"
                            type="text"
                            defaultValue=""
                            placeholder="Subject*"
                            required="required"
                          />
                        </span>
                      </label>
                    </div>
                    <div className="col-sm-12 ttm-box-col-wrapper">
                      <label>
                        <span className="text-input margin_bottom0">
                          <textarea
                            name="message"
                            cols={40}
                            rows={6}
                            placeholder="Your Message"
                            aria-required="true"
                            defaultValue={""}
                          />
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <ReCAPTCHA
                        sitekey="6LeqtMUpAAAAAME8SjXlQpSurZMUjSPGqZqNAOH5"
                        onChange={(val) => setCapVal(val)}
                      />
                      <button
                        className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-fill ttm-btn-color-skincolor w-100"
                        type="submit"
                        value="Send"
                        disabled={!capVal}
                      >
                        Send Now!
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ttm-row padding_zero-section mt_100 res-991-margin_top40 res-991-margin_bottom40 clearfix">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="featured-icon-box icon-align-before-content style9">
                <div className="featured-icon">
                  <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                    <i className="fa fa-location-dot" />
                  </div>
                </div>
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>Address</h3>
                  </div>
                  <div className="featured-desc">
                    <p>
                      2522 Hunt Rd, Land O' Lakes <br /> FL 34638
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="featured-icon-box icon-align-before-content style9">
                <div className="featured-icon">
                  <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                    <i className="fa fa-phone" />
                  </div>
                </div>
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>Call us</h3>
                  </div>
                  <div className="featured-desc">
                    <a href="tel:18139965882">1 (813) 996-5882</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="featured-icon-box icon-align-before-content style9">
                <div className="featured-icon">
                  <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                    <i className="fa fa-envelope" />
                  </div>
                </div>
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>Email Us</h3>
                  </div>
                  <div className="featured-desc">
                    <a href="mailto:bidrequests@brwcontracting.org">
                      bidrequests@brwcontracting.org
                    </a>{" "}
                    <br />
                    <a href="mailto:careers@brwcontracting.org">
                      careers@brwcontracting.org
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div id="google_map" className="google_map mt_90 res-991-margin_top0">
        <div className="map_container clearfix">
          <div id="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1027.0102706222422!2d-82.46876837324898!3d28.19403036810186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c2bbfa4c781b39%3A0xdafff6d9ce08c51b!2sBRW%20Contracting%20Inc!5e0!3m2!1sen!2sus!4v1675276143283!5m2!1sen!2sus"
              height={550}
              width={100}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
