import React from "react";
import Project from "../../../projects.json";

function Sr50() {
  const project = Project.projects.find((project) => project.id === 14);

  const currentUrl = window.location.href;
  const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    currentUrl
  )}`;
  const liUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
    currentUrl
  )}`;

  function shareToFacebook() {
    window.open(fbUrl, "_blank", "width=600,height=400");
  }

  function shareToLinkedIn() {
    window.open(liUrl, "_blank", "width=600,height=400");
  }

  return (
    <>
      <div className="ttm-page-title ttm-bg clearfix">
        <div className="ttm-row-wrapper-bg-layer" />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="ttm-page-title-row-inner">
                <div className="page-title-heading">
                  <h2 className="title">{project.title}</h2>
                </div>
                <div className="breadcrumb-wrapper">
                  <span>
                    <a href="/projects">&nbsp; Projects</a>
                  </span>
                  <span>Project Details</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="site-main">
        <section className="ttm-row project-single-section clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ttm-pf-single-content-wrapper">
                  <div className="ttm-pf-single-content-wrapper-innerbox">
                    <div className="row" style={{ justifyContent: "center" }}>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="featured-icon-box style10 without-icon">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5 className="mb-0 fs-17">Location:</h5>
                            </div>
                            <div className="featured-desc">
                              <p>{project.location}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="featured-icon-box style10 without-icon">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5 className="mb-0 fs-17">Date Started:</h5>
                            </div>
                            <div className="featured-desc">
                              <p>{project.started}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="featured-icon-box style10 without-icon">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5 className="mb-0 fs-17">Date Completed:</h5>
                            </div>
                            <div className="featured-desc">
                              <p>{project.ended}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="margin_bottom50 margin_top50 portfolio-single text-center position-relative">
                            <ul className="social-icons list-inline">
                              <li>
                                <a
                                  id="facebook-share-btn"
                                  onClick={shareToFacebook}
                                >
                                  <i className="fa fa-facebook" />
                                </a>
                              </li>
                              <li>
                                <a
                                  id="linkedin-share-btn"
                                  onClick={shareToLinkedIn}
                                >
                                  <i className="fa fa-linkedin" />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 col-lg-12">
                          <div className="slider ttm_pf_image-wrapper">
                            <div>
                              <a
                                data-lightbox="sr50-top"
                                href="/images/projects/sr50/DJI_0035.jpg"
                              >
                                <img
                                  alt="Slide 1"
                                  className="img-fluid"
                                  src="/images/projects/sr50/DJI_0035.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="sr50-top"
                                href="/images/projects/sr50/DJI_0041.jpg"
                              >
                                <img
                                  alt="Slide 2"
                                  className="img-fluid"
                                  src="/images/projects/sr50/DJI_0041.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="sr50-top"
                                href="/images/projects/sr50/DJI_0045.jpg"
                              >
                                <img
                                  alt="Slide 3"
                                  className="img-fluid"
                                  src="/images/projects/sr50/DJI_0045.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="sr50-top"
                                href="/images/projects/sr50/DJI_0053.jpg"
                              >
                                <img
                                  alt="Slide 4"
                                  className="img-fluid"
                                  src="/images/projects/sr50/DJI_0053.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="sr50-top"
                                href="/images/projects/sr50/DJI_0063.jpg"
                              >
                                <img
                                  alt="Slide 5"
                                  className="img-fluid"
                                  src="/images/projects/sr50/DJI_0063.jpg"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="ttm-pf-single-content-area">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="margin_top40">
                                <h2>{project.title}</h2>
                                <p>
                                  In a successful partnership with PCS Civil,
                                  BRW has played a crucial role in the multilane
                                  widening of SR 50, spanning the distance
                                  between I-75 and US 301. The project's scope
                                  involved BRW's expert execution of earthwork
                                  operations, entailing the excavation of over a
                                  dozen stormwater ponds, ingeniously utilized
                                  as embankments for the roadway construction.
                                  Beyond earthwork, BRW's prowess shone through
                                  as they skillfully installed several miles of
                                  water, reclaimed, and sewer pipe, along with
                                  the necessary associated structures. Moreover,
                                  they accomplished the installation of several
                                  thousand linear feet of various diameter RCP
                                  (Reinforced Concrete Pipe) storm piping and
                                  the relevant structures, augmenting the
                                  infrastructure's overall effectiveness. <br />
                                  <br />
                                  Through seamless coordination and relentless
                                  dedication, BRW's involvement significantly
                                  contributed to the project's remarkable
                                  success, empowering PCS Civil to surpass
                                  expectations and complete the endeavor ahead
                                  of schedule. As SR 50 undergoes transformation
                                  into a more efficient and accommodating
                                  thoroughfare, BRW's expertise and
                                  collaborative spirit stand out as driving
                                  forces behind this substantial infrastructure
                                  enhancement.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-sm-6">
                              <div className="ttm_single_image-wrapper text-left padding_top15">
                                <a
                                  data-lightbox="sr50-bottom"
                                  href="/images/projects/sr50/DJI_0066.jpg"
                                >
                                  <img
                                    alt="single-img-10"
                                    className="img-fluid"
                                    src="/images/projects/sr50/DJI_0066.jpg"
                                  />
                                </a>
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <div className="ttm_single_image-wrapper text-left padding_top15">
                                <a
                                  data-lightbox="sr50-bottom"
                                  href="/images/projects/sr50/DJI_0076.jpg"
                                >
                                  <img
                                    alt="single-img-11"
                                    className="img-fluid"
                                    src="/images/projects/sr50/DJI_0076.jpg"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Sr50;
