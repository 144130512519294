import React from "react";
import Project from "../../../projects.json";

function lexingtonCommons() {
  const project = Project.projects.find((project) => project.id === 9);

  const currentUrl = window.location.href;
  const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    currentUrl
  )}`;
  const liUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
    currentUrl
  )}`;

  function shareToFacebook() {
    window.open(fbUrl, "_blank", "width=600,height=400");
  }

  function shareToLinkedIn() {
    window.open(liUrl, "_blank", "width=600,height=400");
  }

  return (
    <>
      <div className="ttm-page-title ttm-bg clearfix">
        <div className="ttm-row-wrapper-bg-layer" />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="ttm-page-title-row-inner">
                <div className="page-title-heading">
                  <h2 className="title">{project.title}</h2>
                </div>
                <div className="breadcrumb-wrapper">
                  <span>
                    <a href="/projects">&nbsp; Projects</a>
                  </span>
                  <span>Project Details</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="site-main">
        <section className="ttm-row project-single-section clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ttm-pf-single-content-wrapper">
                  <div className="ttm-pf-single-content-wrapper-innerbox">
                    <div className="row" style={{ justifyContent: "center" }}>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="featured-icon-box style10 without-icon">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5 className="mb-0 fs-17">Location:</h5>
                            </div>
                            <div className="featured-desc">
                              <p>{project.location}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="featured-icon-box style10 without-icon">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5 className="mb-0 fs-17">Date Started:</h5>
                            </div>
                            <div className="featured-desc">
                              <p>{project.started}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="featured-icon-box style10 without-icon">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5 className="mb-0 fs-17">Date Completed:</h5>
                            </div>
                            <div className="featured-desc">
                              <p>{project.ended}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="margin_bottom50 margin_top50 portfolio-single text-center position-relative">
                            <ul className="social-icons list-inline">
                              <li>
                                <a
                                  id="facebook-share-btn"
                                  onClick={shareToFacebook}
                                >
                                  <i className="fa fa-facebook" />
                                </a>
                              </li>
                              <li>
                                <a
                                  id="linkedin-share-btn"
                                  onClick={shareToLinkedIn}
                                >
                                  <i className="fa fa-linkedin" />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 col-lg-12">
                          <div className="slider ttm_pf_image-wrapper">
                            <div>
                              <a
                                data-lightbox="commons-top"
                                href="/images/projects/lexington commons/DJI_0043.jpg"
                              >
                                <img
                                  alt="Slide 1"
                                  className="img-fluid"
                                  src="/images/projects/lexington commons/DJI_0052.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="commons-top"
                                href="/images/projects/lexington commons/DJI_0049.jpg"
                              >
                                <img
                                  alt="Slide 2"
                                  className="img-fluid"
                                  src="/images/projects/lexington commons/DJI_0049.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="commons-top"
                                href="/images/projects/lexington commons/DJI_0052.jpg"
                              >
                                <img
                                  alt="Slide 3"
                                  className="img-fluid"
                                  src="/images/projects/lexington commons/DJI_0052.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="commons-top"
                                href="/images/projects/lexington commons/DJI_0060.jpg"
                              >
                                <img
                                  alt="Slide 4"
                                  className="img-fluid"
                                  src="/images/projects/lexington commons/DJI_0060.jpg"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="ttm-pf-single-content-area">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="margin_top40">
                                <h2>{project.title}</h2>
                                <p>
                                  We are thrilled to announce the successful
                                  completion of the Lexington Oaks Commons
                                  project, a highly fruitful collaboration with
                                  the esteemed Terra Management Group. This
                                  visionary endeavor entailed the sitework
                                  package for a future shopping center, destined
                                  to become a bustling hub of commercial
                                  activity. <br />
                                  <br />
                                  Our comprehensive scope of work included the
                                  skillful construction of ponds and mitigation
                                  areas, effectively blending functionality with
                                  aesthetics to create an appealing landscape.
                                  The roadway construction, marked by expert
                                  asphalt installation and new signalization,
                                  ensures a smooth and safe traffic flow for
                                  visitors and patrons alike. <br />
                                  <br />
                                  Employing meticulous earthwork operations, we
                                  meticulously crafted the lots, which were
                                  later transformed into inviting spaces through
                                  careful seeding and mulching. To support the
                                  infrastructure's resilience, we masterfully
                                  executed storm and utility installation, even
                                  incorporating a lift station for efficient
                                  wastewater management. <br />
                                  <br />
                                  As a testament to our dedication to enhancing
                                  traffic safety, we successfully completed the
                                  construction of widened turning lanes on the
                                  county road, providing easier access and
                                  convenience for motorists.
                                  <br />
                                  Lexington Oaks Commons stands as a remarkable
                                  testament to the collaborative spirit between
                                  our team and Terra Management Group. The
                                  future shopping center promises to invigorate
                                  the local community and elevate the retail
                                  landscape in this vibrant area. With pride in
                                  our craftsmanship and a commitment to
                                  excellence, we celebrate the realization of
                                  this transformative project that will serve as
                                  a testament to our shared vision for years to
                                  come.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-sm-6">
                              <div className="ttm_single_image-wrapper text-left padding_top15">
                                <a
                                  data-lightbox="commons-bottom"
                                  href="/images/projects/lexington commons/DJI_0023.jpg"
                                >
                                  <img
                                    alt="single-img-10"
                                    className="img-fluid"
                                    src="/images/projects/lexington commons/DJI_0023.jpg"
                                  />
                                </a>
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <div className="ttm_single_image-wrapper text-left padding_top15">
                                <a
                                  data-lightbox="commons-bottom"
                                  href="/images/projects/lexington commons/DJI_0026.jpg"
                                >
                                  <img
                                    alt="single-img-11"
                                    className="img-fluid"
                                    src="/images/projects/lexington commons/DJI_0026.jpg"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default lexingtonCommons;
