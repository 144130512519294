import React from "react";
import Application from "../../assets/documents/BRW-App.pdf";
import Appthumb from "../../assets/documents/Application-thumb.jpg";
import Jobs from "../../components/career jobs/jobs";

const Careers = () => {
  return (
    <>
      <div className="ttm-page-title ttm-bg clearfix">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="ttm-page-title-row-inner">
                <div className="page-title-heading">
                  <h2 className="title">Careers</h2>
                </div>
                <div className="breadcrumb-wrapper">
                  <span>
                    <a title="Homepage" href="/">
                      <i className="fa fa-house" /> &nbsp; Home
                    </a>
                  </span>
                  <span>Careers</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="site-main">
        <section className="ttm-row about-section clearfix">
          <div className="container">
            <div className="row g-0 res-991-padding_lr15">
              <div className="col-lg-6">
                <div className="section-title">
                  <div className="title-header">
                    <h3>Why Join Us</h3>
                    <h2 className="title">
                      We Are Always Looking For <span>Talented</span> People To
                      Join Our Team
                    </h2>
                  </div>
                  <div className="title-desc">
                    <p>
                      BRW Contracting offers a complete benefit package
                      including major medical, dental, vision, group accident,
                      critical illness, short term disability, long term
                      disability, life insurance, 401k with company match, paid
                      vacations, paid personal days, and paid holidays.
                    </p>
                    <br />
                    <h4>Now Hiring Positions:</h4>
                    <Jobs />
                    <br />
                    <p>
                      Click{" "}
                      <strong>
                        <a
                          target="_blank"
                          href="https://www.indeed.com/cmp/B.r.w.-Contracting,-Inc./jobs?q=&l=#cmp-skip-header-desktop"
                        >
                          here
                        </a>
                      </strong>{" "}
                      to apply to our available job opportunities. <br />
                      <br />
                      Click on the application image to download. Afterward,
                      complete the form and feel free to send it to us via email
                      at
                      <strong>
                        <a href="mailto:careers@brwcontracting.org">
                          &nbsp;careers@brwcontracting.org
                        </a>
                      </strong>
                      &nbsp; or print it out and submit it in person at our
                      office. Feel free to contact us by phone or visit our
                      office. We eagerly await your response.
                    </p>

                    <p>
                      To apply for the <b>CDL Driver Position</b>, kindly click
                      the link provided below. We look forward to receiving your
                      application soon.
                      <br />
                      <strong>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://www.mydriverfiles.com/brw-contracting-inc/apply"
                        >
                          https://www.mydriverfiles.com/brw-contracting-inc/apply.
                        </a>
                      </strong>
                      <br />
                      <br />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="ttm_single_image-wrapper text-end res-991-padding_top30">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={Application}
                    download
                  >
                    <img
                      className="img-fluid auto_size"
                      src={Appthumb}
                      alt="single-06"
                      height={597}
                      width={569}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Careers;
