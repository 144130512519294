import React from "react";
import Project from "../../../projects.json";

function sr52() {
  const project = Project.projects.find((project) => project.id === 15);

  const currentUrl = window.location.href;
  const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    currentUrl
  )}`;
  const liUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
    currentUrl
  )}`;

  function shareToFacebook() {
    window.open(fbUrl, "_blank", "width=600,height=400");
  }

  function shareToLinkedIn() {
    window.open(liUrl, "_blank", "width=600,height=400");
  }
  return (
    <>
      <div className="ttm-page-title ttm-bg clearfix">
        <div className="ttm-row-wrapper-bg-layer" />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="ttm-page-title-row-inner">
                <div className="page-title-heading">
                  <h2 className="title">{project.title}</h2>
                </div>
                <div className="breadcrumb-wrapper">
                  <span>
                    <a href="/projects">&nbsp; Projects</a>
                  </span>
                  <span>Project Details</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="site-main">
        <section className="ttm-row project-single-section clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ttm-pf-single-content-wrapper">
                  <div className="ttm-pf-single-content-wrapper-innerbox">
                    <div className="row" style={{ justifyContent: "center" }}>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="featured-icon-box style10 without-icon">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5 className="mb-0 fs-17">Location:</h5>
                            </div>
                            <div className="featured-desc">
                              <p>{project.location}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="featured-icon-box style10 without-icon">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5 className="mb-0 fs-17">Date Started:</h5>
                            </div>
                            <div className="featured-desc">
                              <p>{project.started}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="featured-icon-box style10 without-icon">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5 className="mb-0 fs-17">Date Completed:</h5>
                            </div>
                            <div className="featured-desc">
                              <p>{project.ended}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="margin_bottom50 margin_top50 portfolio-single text-center position-relative">
                            <ul className="social-icons list-inline">
                              <li>
                                <a
                                  id="facebook-share-btn"
                                  onClick={shareToFacebook}
                                >
                                  <i className="fa fa-facebook" />
                                </a>
                              </li>
                              <li>
                                <a
                                  id="linkedin-share-btn"
                                  onClick={shareToLinkedIn}
                                >
                                  <i className="fa fa-linkedin" />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 col-lg-12">
                          <div className="slider ttm_pf_image-wrapper">
                            <div>
                              <a
                                data-lightbox="sr52-top"
                                href="/images/projects/sr52/DJI_0058.jpg"
                              >
                                <img
                                  alt="Slide 1"
                                  className="img-fluid"
                                  src="/images/projects/sr52/DJI_0058.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="sr52-top"
                                href="/images/projects/sr52/DJI_0059.jpg"
                              >
                                <img
                                  alt="Slide 2"
                                  className="img-fluid"
                                  src="/images/projects/sr52/DJI_0059.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="sr52-top"
                                href="/images/projects/sr52/DJI_0065.jpg"
                              >
                                <img
                                  alt="Slide 3"
                                  className="img-fluid"
                                  src="/images/projects/sr52/DJI_0065.jpg"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="ttm-pf-single-content-area">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="margin_top40">
                                <h2>{project.title}</h2>
                                <p>
                                  In an exemplary collaboration, BRW and PCS
                                  Civil have come together to undertake a
                                  significant project aimed at expanding the
                                  motor vehicle capacity on a nearly 5-mile
                                  segment of SR 52, stretching from US 41 to the
                                  Suncoast Parkway. This endeavor came to
                                  fruition after PCS Civil was entrusted with
                                  the completion of the project due to the
                                  unfortunate default of a previous contractor.
                                  Playing a pivotal role in the development
                                  process, BRW took charge of earthwork
                                  operations with finesse. This involved the
                                  skilled excavation of six stormwater ponds,
                                  ingeniously utilized to provide the
                                  much-needed embankment for the road
                                  construction. The precision and expertise
                                  demonstrated by BRW throughout the earthwork
                                  phase were key factors in the success of this
                                  ambitious venture. Notably, BRW was also
                                  tasked with the installation of several
                                  thousands of linear feet of 48” Reinforced
                                  Concrete Pipe (RCP) and the establishment of
                                  essential storm structures. This crucial
                                  aspect of the project further underlines BRW's
                                  versatile capabilities in delivering
                                  comprehensive solutions for roadway
                                  infrastructure. <br />
                                  <br />
                                  As the SR 52 expansion project advances, the
                                  joint efforts of BRW and PCS Civil continue to
                                  drive progress, ensuring a smoother and safer
                                  commuting experience for the public while
                                  upholding the highest standards of engineering
                                  excellence and environmental supervision.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-sm-6">
                              <div className="ttm_single_image-wrapper text-left padding_top15">
                                <a
                                  data-lightbox="sr52-bottom"
                                  href="/images/projects/sr52/DJI_0068.jpg"
                                >
                                  <img
                                    alt="single-img-10"
                                    className="img-fluid"
                                    src="/images/projects/sr52/DJI_0068.jpg"
                                  />
                                </a>
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <div className="ttm_single_image-wrapper text-left padding_top15">
                                <a
                                  data-lightbox="sr52-bottom"
                                  href="/images/projects/sr52/DJI_0067.jpg"
                                >
                                  <img
                                    alt="single-img-11"
                                    className="img-fluid"
                                    src="/images/projects/sr52/DJI_0067.jpg"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default sr52;
