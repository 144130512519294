import React from "react";
import TeamMemberCard from "../../components/teamMemberCard/TeamMemberCard";

function Team() {
  return (
    <>
      <div className="ttm-page-title ttm-bg clearfix">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="ttm-page-title-row-inner">
                <div className="page-title-heading">
                  <h2 className="title">Our Team</h2>
                </div>
                <div className="breadcrumb-wrapper">
                  <span>
                    <a title="Homepage" href="/about">
                      {" "}
                      &nbsp; About
                    </a>
                  </span>
                  <span>Our Team </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="site-main">
        <section className="ttm-row team-section clearfix">
          <div className="container">
            <div className="section-title title-style-center_text">
              <div className="other-title-header">
                <h3>Meet Our Talented Team</h3>
                <h2 className="title">
                  Meet the <span>Experts</span> Who Make It Happen
                </h2>
              </div>
            </div>
            <TeamMemberCard />
          </div>
        </section>
      </div>
    </>
  );
}

export default Team;
