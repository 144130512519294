import React from "react";
import teamMembers from "../../TeamMembers.json";

const TeamMemberCard = () => {
  if (!teamMembers || teamMembers.length === 0) {
    return <div>No team members found</div>;
  }

  return (
    <>
      <div className="row" style={{ justifyContent: "center" }}>
        {teamMembers.TeamMembers.map((member) => (
          <div className="col-lg-3 col-md-3 col-6" key={member.id}>
            <div className="featured-imagebox featured-imagebox-team style1">
              <div className="ttm-team-box-view-overlay">
                <div className="featured-thumbnail">
                  <img
                    className="img-fluid"
                    src={member.image}
                    alt={member.name}
                  />
                </div>
              </div>
              <div className="featured-content">
                <div className="featured-title">
                  <h5>{member.name}</h5>
                </div>
                <p className="team-position">{member.position}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default TeamMemberCard;
