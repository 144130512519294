import React from "react";
import Logo from "../../images/brwlogo.avif";
import $ from "jquery";

function Footer() {
  return (
    <>
      <footer className="footer widget-footer clearfix">
        <div className="first-footer">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 widget-area"></div>
              <div
                className="col-xs-12 col-sm-12 col-md-12 col-lg-4 widget-area"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="featured-icon-box icon-align-before-content">
                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>Need assistance?</h3>
                    </div>
                    <div className="featured-desc">
                      <p>Connect with our team for any inquiries.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-xs-12 col-sm-12 col-md-12 col-lg-4 widget-area"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="widget newsletter_widget clearfix res-991-padding_top30">
                  <form id="contact-form" action="/contact">
                    <div className="clearfix" id="contact-content">
                      <button
                        className="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-fill ttm-btn-color-darkgrey"
                        style={{ zIndex: "auto" }}
                      >
                        Contact Us Today!
                        <i
                          className="fa fa-long-arrow-right"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="second-footer ttm-bgimage-yes bg-footer ttm-bg ttm-bgcolor-darkgrey">
          <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 widget-area">
                <div className="widget-latest-tweets mt_140 res-991-margin_top0 clearfix">
                  <div className="widgte-text">
                    <div className="widgte-title">
                      <img src={Logo} alt="" />
                    </div>
                    <div className="widgte-title">
                      <h4>Excellence is Standard</h4>
                    </div>
                    <div className="">
                      <p>
                        BRW Contracting is a full-service site development
                        company located in Land O' Lakes, Florida.
                      </p>
                    </div>
                    <div className="widget_social padding_top10 clearfix">
                      <div className="social-icons">
                        <ul className="social-icons list-inline">
                          <li>
                            <a
                              className="tooltip-top"
                              target="_blank"
                              href="https://www.instagram.com/brwcontractinginc"
                              rel="noreferrer"
                              aria-label="instagram"
                              data-tooltip="Instagram"
                            >
                              <i className="fa fa-instagram" />
                            </a>
                          </li>
                          <li>
                            <a
                              className="tooltip-top"
                              target="_blank"
                              href="https://www.youtube.com/channel/UCZK7Sk8HvNqLYKcdx4w2xrQ"
                              rel="noreferrer"
                              aria-label="youtube"
                              data-tooltip="Youtube"
                            >
                              <i className="fa fa-youtube" />
                            </a>
                          </li>
                          <li>
                            <a
                              className="tooltip-top"
                              target="_blank"
                              href="https://www.facebook.com/brwcontracting"
                              rel="noreferrer"
                              aria-label="facebook"
                              data-tooltip="Facebook"
                            >
                              <i className="fa fa-facebook" />
                            </a>
                          </li>
                          <li>
                            <a
                              className="tooltip-top"
                              target="_blank"
                              href="https://www.linkedin.com/company/brwcontracting"
                              rel="noreferrer"
                              aria-label="linkedin"
                              data-tooltip="linkedin"
                            >
                              <i className="fa fa-linkedin" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 widget-area">
                <div className="widget widget_nav_menu clearfix">
                  <h3 className="widget-title">Useful Links</h3>
                  <ul id="menu-footer-quick-links">
                    <li>
                      <a href="/about">
                        {" "}
                        <i className="fa fa-arrow-right" />
                        About Us
                      </a>
                    </li>
                    <li>
                      <a href="/team">
                        <i className="fa fa-arrow-right" />
                        Meet Our Team
                      </a>
                    </li>
                    <li>
                      <a href="/services">
                        <i className="fa fa-arrow-right" />
                        Our Services
                      </a>
                    </li>
                    <li>
                      <a href="/projects">
                        <i className="fa fa-arrow-right" />
                        Projects
                      </a>
                    </li>
                    <li>
                      <a href="/careers">
                        <i className="fa fa-arrow-right" />
                        Careers
                      </a>
                    </li>
                    <li>
                      <a href="/contact">
                        <i className="fa fa-arrow-right" />
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 widget-area">
                <div className="widget widget_adress clearfix">
                  <h3 className="widget-title">Contact Information</h3>
                  <ul className="widget-post">
                    <li className="footer-adress">
                      <i class="fa fa-phone" aria-hidden="true"></i>
                      <a href="tel:18139965882">1 (813) 996-5882</a>
                    </li>
                    <li className="footer-adress">
                      <i class="fa fa-map-marker" aria-hidden="true"></i>
                      <a href="https://www.google.com/maps/dir//brw+contracting/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x88c2bbfa4c781b39:0xdafff6d9ce08c51b?sa=X&ved=2ahUKEwiO5uTz9fT8AhW-TTABHXPFABoQ9Rd6BAhIEAU">
                        2522 Hunt Rd, Land O' Lakes, FL 34638
                      </a>
                    </li>
                    <li className="footer-adress">
                      <i class="fa fa-envelope" aria-hidden="true"></i>
                      <a href="mailto:bidrequests@brwcontracting.org">
                        bidrequests@brwcontracting.org
                      </a>
                    </li>
                    <li className="footer-adress">
                      <i class="fa fa-clock-o" aria-hidden="true"></i>
                      Mon-Friday..... 8AM - 5PM
                    </li>
                    <li className="footer-adress">
                      <i class="fa fa-clock-o" aria-hidden="true"></i>
                      Sat-Sunday.... CLOSED
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-footer-text ttm-bg copyright">
            <div className="truck3">
              <div className="container">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="text-left">
                      <span className="cpy-text">
                        © Copyright 2023 <a href="/"> BRW Contracting</a> All
                        Rights Reserved.
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <ul className="footer-nav-menu text-end">
                      <li>
                        <a href="/about">About Us</a>
                      </li>
                      <li>
                        <a href="/services">Services</a>
                      </li>
                      <li>
                        <a href="/contact">Contact</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
