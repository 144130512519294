import React from "react";
import Service1 from "../../images/services/earthwork.jpg";
import Service2 from "../../images/services/utilities.jpg";
import Service3 from "../../images/services/IMG_2370.jpg";
import Service4 from "../../images/services/round.jpg";
import Service5 from "../../images/services/DSC_2376.jpg";
import Service7 from "../../images/services/DJI_0098.jpg";
import ServiceArea from "../../images/Screenshot 2024-02-21 at 10.28.28 AM.png";

const Services = () => {
  return (
    <>
      <div className="ttm-page-title ttm-bg clearfix">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="ttm-page-title-row-inner">
                <div className="page-title-heading">
                  <h2 className="title">Services</h2>
                </div>
                <div className="breadcrumb-wrapper">
                  <span>
                    <a title="Homepage" href="/">
                      <i className="fa fa-house" /> &nbsp; Home
                    </a>
                  </span>
                  <span>Services</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <>
        <div className="site-main">
          <section className="ttm-row service2-section clearfix">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title title-style-center_text">
                    <div className="other-title-header">
                      <h2 className="title">
                        Our Service
                        <strong className="ttm-textcolor-skincolor">
                          &nbsp;Area
                        </strong>
                      </h2>
                      <img
                        className="serviceimg img-fluid"
                        src="/static/media/Screenshot 2024-02-21 at 10.28.28 AM.01ce6ec5cfad05bc5be7.png"
                        alt=""
                        height="100%"
                        width="100%"
                        style={{ width: 600, marginBottom: 50 }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="section-title title-style-center_text">
                    <div className="other-title-header">
                      <h3>ABOUT SERVICES</h3>
                      <h2 className="title">
                        Full-Service Site Development
                        <strong className="ttm-textcolor-skincolor">
                          &nbsp;Services
                        </strong>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 res-375-margin_top50">
                      <div className="featured-imagebox featured-imagebox-sevices style1">
                        <div className="featured-thumbnail">
                          <a href="/earthwork" tabIndex={-1}>
                            <img className="img-fluid" src={Service1} alt="" />
                          </a>
                          <div className="ttm-media-link">
                            <a
                              href="/earthwork"
                              tabIndex={-1}
                              className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-skincolor ttm-icon_element-size-md"
                            >
                              <i className="flaticon flaticon-construction-machine" />
                            </a>
                          </div>
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">
                            <h3>
                              <a href="/earthwork" tabIndex={-1}>
                                Earthwork
                              </a>
                            </h3>
                          </div>
                          <div className="featured-desc">
                            Our earthwork service provides expert grading,
                            excavation, and land clearing solutions...
                          </div>
                          <div className="ttm-details-link">
                            <a
                              href="/earthwork"
                              tabIndex={-1}
                              className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-darkgrey ttm-icon_element-size-xs"
                            >
                              <i className="ti ti-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 res-375-margin_top50">
                      <div className="featured-imagebox featured-imagebox-sevices style1">
                        <div className="featured-thumbnail">
                          <a href="/undergroundUtilities" tabIndex={-1}>
                            <img className="img-fluid" src={Service2} alt="" />
                          </a>
                          <div className="ttm-media-link">
                            <a
                              href="/undergroundUtilities"
                              tabIndex={-1}
                              className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-skincolor ttm-icon_element-size-md"
                            >
                              <i className="flaticon flaticon-worker" />
                            </a>
                          </div>
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">
                            <h3>
                              <a href="/undergroundUtilities" tabIndex={-1}>
                                Underground Utilities
                              </a>
                            </h3>
                          </div>
                          <div className="featured-desc">
                            Our team has the expertise to handle all types of
                            underground utilities, including water lines...
                          </div>
                          <div className="ttm-details-link">
                            <a
                              href="/undergroundUtilities"
                              tabIndex={-1}
                              className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-darkgrey ttm-icon_element-size-xs"
                            >
                              <i className="ti ti-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 res-991-margin_top30 res-375-margin_top50">
                      <div className="featured-imagebox featured-imagebox-sevices style1">
                        <div className="featured-thumbnail">
                          <a href="/concrete" tabIndex={-1}>
                            <img className="img-fluid" src={Service3} alt="" />
                          </a>
                          <div className="ttm-media-link">
                            <a
                              href="/concrete"
                              tabIndex={-1}
                              className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-skincolor ttm-icon_element-size-md"
                            >
                              <i className="flaticon flaticon-mixer-truck" />
                            </a>
                          </div>
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">
                            <h3>
                              <a href="/concrete" tabIndex={-1}>
                                Concrete
                              </a>
                            </h3>
                          </div>
                          <div className="featured-desc">
                            From cast in place to sidewalks and curbs, we are
                            your one-stop-shop for all your...
                          </div>
                        </div>
                        <div className="ttm-details-link">
                          <a
                            href="/concrete"
                            tabIndex={-1}
                            className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-darkgrey ttm-icon_element-size-xs"
                          >
                            <i className="ti ti-arrow-right" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row my-3">
                <div className="col-lg-12">
                  <div className="row" style={{ justifyContent: "center" }}>
                    <div className="col-lg-4 col-md-6 res-375-margin_top50">
                      <div className="featured-imagebox featured-imagebox-sevices style1">
                        <div className="featured-thumbnail">
                          <a href="/roadwayConstruction" tabIndex={-1}>
                            <img className="img-fluid" src={Service4} alt="" />
                          </a>
                          <div className="ttm-media-link">
                            <a
                              href="/roadwayConstruction"
                              tabIndex={-1}
                              className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-skincolor ttm-icon_element-size-md"
                            >
                              <i className="flaticon flaticon-sketch" />
                            </a>
                          </div>
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">
                            <h3>
                              <a href="/roadwayConstruction" tabIndex={-1}>
                                Roadway Construction
                              </a>
                            </h3>
                          </div>
                          <div className="featured-desc">
                            <p>
                              We provide comprehensive roadway construction
                              services, including design, excavation...
                            </p>
                          </div>
                          <div className="ttm-details-link">
                            <a
                              href="/roadwayConstruction"
                              tabIndex={-1}
                              className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-darkgrey ttm-icon_element-size-xs"
                            >
                              <i className="ti ti-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 res-375-margin_top50">
                      <div className="featured-imagebox featured-imagebox-sevices style1">
                        <div className="featured-thumbnail">
                          <a href="/pavingStriping" tabIndex={-1}>
                            <img className="img-fluid" src={Service5} alt="" />
                          </a>
                          <div className="ttm-media-link">
                            <a
                              href="/pavingStriping"
                              tabIndex={-1}
                              className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-skincolor ttm-icon_element-size-md"
                            >
                              <i className="flaticon flaticon-worker-1" />
                            </a>
                          </div>
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">
                            <h3>
                              <a href="/pavingStriping" tabIndex={-1}>
                                Paving and Striping
                              </a>
                            </h3>
                          </div>
                          <div className="featured-desc">
                            <p>
                              Our paving and striping services include asphalt
                              and concrete paving, and parking...
                            </p>
                          </div>
                          <div className="ttm-details-link">
                            <a
                              href="/pavingStriping"
                              tabIndex={-1}
                              className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-darkgrey ttm-icon_element-size-xs"
                            >
                              <i className="ti ti-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 res-375-margin_top50">
                      <div className="featured-imagebox featured-imagebox-sevices style1">
                        <div className="featured-thumbnail">
                          <a href="/segmentedRetainingWalls" tabIndex={-1}>
                            <img className="img-fluid" src={Service7} alt="" />
                          </a>
                          <div className="ttm-media-link">
                            <a
                              href="/retainingWalls"
                              tabIndex={-1}
                              className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-skincolor ttm-icon_element-size-md"
                            >
                              <i className="flaticon flaticon-worker-1" />
                            </a>
                          </div>
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">
                            <h3>
                              <a href="/segmentedRetainingWalls" tabIndex={-1}>
                                Segmented Block Retaining Walls
                              </a>
                            </h3>
                          </div>
                          <div className="featured-desc">
                            <p>
                              Our skilled team specializes in designing and
                              building durable retaining walls that not...
                            </p>
                          </div>
                          <div className="ttm-details-link">
                            <a
                              href="/pavingStriping"
                              tabIndex={-1}
                              className="ttm-icon ttm-icon_element-fill ttm-icon_element-color-darkgrey ttm-icon_element-size-xs"
                            >
                              <i className="ti ti-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    </>
  );
};

export default Services;
