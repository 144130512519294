import React from "react";
import Project from "../../../projects.json";

function northTampa75BusinessCenter() {
  const project = Project.projects.find((project) => project.id === 31);

  const currentUrl = window.location.href;
  const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    currentUrl
  )}`;
  const liUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
    currentUrl
  )}`;

  function shareToFacebook() {
    window.open(fbUrl, "_blank", "width=600,height=400");
  }

  function shareToLinkedIn() {
    window.open(liUrl, "_blank", "width=600,height=400");
  }

  return (
    <>
      <div className="ttm-page-title ttm-bg clearfix">
        <div className="ttm-row-wrapper-bg-layer" />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="ttm-page-title-row-inner">
                <div className="page-title-heading">
                  <h2 className="title">{project.title}</h2>
                </div>
                <div className="breadcrumb-wrapper">
                  <span>
                    <a href="/projects">&nbsp; Projects</a>
                  </span>
                  <span>Project Details</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="site-main">
        <section className="ttm-row project-single-section clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ttm-pf-single-content-wrapper">
                  <div className="ttm-pf-single-content-wrapper-innerbox">
                    <div className="row" style={{ justifyContent: "center" }}>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="featured-icon-box style10 without-icon">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5 className="mb-0 fs-17">Location:</h5>
                            </div>
                            <div className="featured-desc">
                              <p>{project.location}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="featured-icon-box style10 without-icon">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5 className="mb-0 fs-17">Date Started:</h5>
                            </div>
                            <div className="featured-desc">
                              <p>{project.started}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="featured-icon-box style10 without-icon">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5 className="mb-0 fs-17">Date Completed:</h5>
                            </div>
                            <div className="featured-desc">
                              <p>{project.ended}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="margin_bottom50 margin_top50 portfolio-single text-center position-relative">
                            <ul className="social-icons list-inline">
                              <li>
                                <a
                                  id="facebook-share-btn"
                                  onClick={shareToFacebook}
                                >
                                  <i className="fa fa-facebook" />
                                </a>
                              </li>
                              <li>
                                <a
                                  id="linkedin-share-btn"
                                  onClick={shareToLinkedIn}
                                >
                                  <i className="fa fa-linkedin" />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 col-lg-12">
                          <div className="slider ttm_pf_image-wrapper">
                            <div>
                              <a
                                data-lightbox="nt75BusinessCenter-top"
                                href="/images/projects/north tampa bay 75 business center/DJI_0013.jpg"
                              >
                                <img
                                  alt="Slide 1"
                                  className="img-fluid"
                                  src="/images/projects/north tampa bay 75 business center/DJI_0013.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="nt75BusinessCenter-top"
                                href="/images/projects/north tampa bay 75 business center/DJI_0016.jpg"
                              >
                                <img
                                  alt="Slide 2"
                                  className="img-fluid"
                                  src="/images/projects/north tampa bay 75 business center/DJI_0016.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="nt75BusinessCenter-top"
                                href="/images/projects/north tampa bay 75 business center/DJI_0018.jpg"
                              >
                                <img
                                  alt="Slide 3"
                                  className="img-fluid"
                                  src="/images/projects/north tampa bay 75 business center/DJI_0018.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="nt75BusinessCenter-top"
                                href="/images/projects/north tampa bay 75 business center/DJI_0022.jpg"
                              >
                                <img
                                  alt="Slide 4"
                                  className="img-fluid"
                                  src="/images/projects/north tampa bay 75 business center/DJI_0022.jpg"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="ttm-pf-single-content-area">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="margin_top40">
                                <h2>{project.title}</h2>
                                <p>
                                  We're thrilled to announce the successful
                                  completion of the North Tampa Bay 75 Business
                                  Center project, nestled off Old Pasco Rd in
                                  Wesley Chapel, FL.
                                  <br />
                                  Our comprehensive involvement spanned a wide
                                  array of critical tasks. From the initial site
                                  clearing and precise earthwork to the
                                  meticulous construction of building pads. We
                                  ensured the seamless installation of storm
                                  drainage systems and vital underground
                                  utilities, coupled with robust fire protection
                                  systems. Moreover, we focused on enhancing
                                  accessibility and aesthetics through the
                                  creation of concrete sidewalks, curbs, and the
                                  strategic placement of asphalt paving,
                                  signage, and striping. <br />
                                  <br />
                                  This landmark project has enabled the creation
                                  of a 187,000 sq ft warehouse – the first of
                                  several to grace the site just west of
                                  Interstate 75. Once again partnering with
                                  Itasca Construction Associates, BRW looks
                                  forward to continued successful collaborations
                                  in the future.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-sm-6">
                              <div className="ttm_single_image-wrapper text-left padding_top15">
                                <a
                                  data-lightbox="nt75BusinessCenter-bottom"
                                  href="/images/projects/north tampa bay 75 business center/DJI_0024.jpg"
                                >
                                  <img
                                    alt="single-img-10"
                                    className="img-fluid"
                                    src="/images/projects/north tampa bay 75 business center/DJI_0024.jpg"
                                  />
                                </a>
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <div className="ttm_single_image-wrapper text-left padding_top15">
                                <a
                                  data-lightbox="nt75BusinessCenter-bottom"
                                  href="/images/projects/north tampa bay 75 business center/DJI_0025.jpg"
                                >
                                  <img
                                    alt="single-img-11"
                                    className="img-fluid"
                                    src="/images/projects/north tampa bay 75 business center/DJI_0025.jpg"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default northTampa75BusinessCenter;
