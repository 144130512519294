import React from "react";
import Photo from "../../images/services/utilities.jpg";
import Photo2 from "../../images/services/DSC_0561.jpg";
import Photo3 from "../../images/services/DSC_0563.jpg";

function UndergroundUtilities() {
  return (
    <>
      <div
        className="ttm-page-title ttm-bg clearfix"
        style={{
          backgroundImage:
            "url(https://images2.imgbox.com/e3/76/LL3pKTkN_o.png)",
        }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="ttm-page-title-row-inner">
                <div className="page-title-heading">
                  <h2 className="title">Underground Utilities</h2>
                </div>
                <div className="breadcrumb-wrapper">
                  <span>
                    <a title="Homepage" href="/services">
                      <i className="fa fa-house" /> &nbsp; Services
                    </a>
                  </span>
                  <span>Underground Utilities</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="site-main">
        <div className="ttm-row sidebar ttm-sidebar-left clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 widget-area sidebar-left">
                <aside className="widget widget-nav-menu">
                  <ul>
                    <li>
                      <a href="/undergroundUtilities">Underground Utilities</a>
                    </li>
                    <li>
                      <a href="/earthwork">Earthwork</a>
                    </li>
                    <li>
                      <a href="/pavingStriping">Paving & Striping</a>
                    </li>
                    <li>
                      <a href="/roadwayConstruction">Roadway Construction</a>
                    </li>
                    <li>
                      <a href="/segmentedRetainingWalls">
                        Segmented Block Retaining Walls
                      </a>
                    </li>
                    <li>
                      <a href="/concrete">Concrete</a>
                    </li>
                  </ul>
                </aside>
                <aside className="widget widget-banner">
                  <div className="ttm-col-bgcolor-yes ttm-bgcolor-darkgrey col-bg-img-nine ttm-col-bgimage-yes ttm-bg">
                    <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                      <div className="ttm-col-wrapper-bg-layer-inner" />
                    </div>
                    <div className="layer-content">
                      <div className="widget-banner-inner">
                        <h3>Office Location</h3>
                        <p>2522 Hunt Rd, Land O' Lakes, FL 34638</p>
                        <h3>Contact Us</h3>
                        <p className="mb-0">
                          Email: bidrequests@brwcontracting.org
                        </p>
                        <p>Call Us: +1 (813) 996 5882</p>
                        <h3>Office Hours</h3>
                        <p className="mb-0">Monday - Friday</p>
                        <p>08:00 AM - 05:00 PM</p>
                        <p className="mb-0">Saturday - Sunday</p>
                        <p>Closed</p>
                        <a
                          className="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-fill ttm-btn-color-skincolor  margin_top10"
                          href="/contact"
                        >
                          Contact Us Today
                        </a>
                      </div>
                    </div>
                  </div>
                </aside>
              </div>
              <div className="col-lg-8 content-area">
                <div className="ttm-service-single-content-area">
                  <div className="ttm-service-description">
                    <div className="padding_bottom20">
                      <div className="slider ttm_fatured_image-wrapper">
                        <div>
                          <a data-lightbox="undergroundUtilities" href={Photo}>
                            <img
                              alt="Slide 1"
                              className="img-fluid"
                              src={Photo}
                            />
                          </a>
                        </div>

                        <div>
                          <a data-lightbox="undergroundUtilities" href={Photo2}>
                            <img
                              alt="Slide 2"
                              className="img-fluid"
                              src={Photo2}
                            />
                          </a>
                        </div>
                        <div>
                          <a data-lightbox="undergroundUtilities" href={Photo3}>
                            <img
                              alt="Slide 3"
                              className="img-fluid"
                              src={Photo3}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="padding_top30">
                      <h3>
                        Expert Underground Utility Services For Your Project{" "}
                      </h3>
                      <p>
                        We offer a wide range of utility crews that have the
                        knowledge, expertise, manpower, and equipment to handle
                        all types of infrastructure systems including sanitary
                        sewers, lift stations, water mains, underground fire
                        lines, and underground stormwater drainage systems. We
                        take pride in our ability to work safely and efficiently
                        in open and congested areas installing new systems while
                        ensuring the residents and local occupants are not
                        without service.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UndergroundUtilities;
