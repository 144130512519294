import React from "react";
import Project from "../../../projects.json";

function BoarsHeadRanch() {
  const project = Project.projects.find((project) => project.id === 3);

  const currentUrl = window.location.href;
  const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    currentUrl
  )}`;
  const liUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
    currentUrl
  )}`;

  function shareToFacebook() {
    window.open(fbUrl, "_blank", "width=600,height=400");
  }

  function shareToLinkedIn() {
    window.open(liUrl, "_blank", "width=600,height=400");
  }

  return (
    <>
      <div className="ttm-page-title ttm-bg clearfix">
        <div className="ttm-row-wrapper-bg-layer" />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="ttm-page-title-row-inner">
                <div className="page-title-heading">
                  <h2 className="title">{project.title}</h2>
                </div>
                <div className="breadcrumb-wrapper">
                  <span>
                    <a href="/projects">&nbsp; Projects</a>
                  </span>
                  <span>Project Details</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="site-main">
        <section className="ttm-row project-single-section clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ttm-pf-single-content-wrapper">
                  <div className="ttm-pf-single-content-wrapper-innerbox">
                    <div className="row" style={{ justifyContent: "center" }}>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="featured-icon-box style10 without-icon">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5 className="mb-0 fs-17">Location:</h5>
                            </div>
                            <div className="featured-desc">
                              <p>{project.location}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="featured-icon-box style10 without-icon">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5 className="mb-0 fs-17">Date Started:</h5>
                            </div>
                            <div className="featured-desc">
                              <p>{project.started}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="featured-icon-box style10 without-icon">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5 className="mb-0 fs-17">Date Completed:</h5>
                            </div>
                            <div className="featured-desc">
                              <p>{project.ended}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="margin_bottom50 margin_top50 portfolio-single text-center position-relative">
                            <ul className="social-icons list-inline">
                              <li>
                                <a
                                  id="facebook-share-btn"
                                  onClick={shareToFacebook}
                                >
                                  <i className="fa fa-facebook" />
                                </a>
                              </li>
                              <li>
                                <a
                                  id="linkedin-share-btn"
                                  onClick={shareToLinkedIn}
                                >
                                  <i className="fa fa-linkedin" />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 col-lg-12">
                          <div className="slider ttm_pf_image-wrapper">
                            <div>
                              <a
                                data-lightbox="top-boars"
                                href="/images/projects/boarshead ranch/DJI_0018_edited.jpg"
                              >
                                <img
                                  alt="Slide 1"
                                  className="img-fluid"
                                  src="/images/projects/boarshead ranch/DJI_0018_edited.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="top-boars"
                                href="/images/projects/boarshead ranch/DJI_0025.jpg"
                              >
                                <img
                                  alt="Slide 2"
                                  className="img-fluid"
                                  src="/images/projects/boarshead ranch/DJI_0025.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="top-boars"
                                href="/images/projects/boarshead ranch/DJI_0029.jpg"
                              >
                                <img
                                  alt="Slide 3"
                                  className="img-fluid"
                                  src="/images/projects/boarshead ranch/DJI_0029.jpg"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="ttm-pf-single-content-area">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="margin_top40">
                                <h2>
                                  Boarshead Ranch Wetland Mitigation Bank Phase
                                  3A/3B
                                </h2>
                                <p>
                                  The Boarshead Ranch Wetland Mitigation Bank
                                  was a challenging and exciting project that
                                  involved the excavation of approximately
                                  500,000 cubic yards of fill. The primary goal
                                  of this massive excavation was to create a
                                  wetland mitigation bank, which would provide
                                  ecological benefits by restoring and enhancing
                                  wetland habitats. Our team worked closely with
                                  the client to develop a comprehensive plan for
                                  the excavation, ensuring that all work was
                                  done safely, efficiently, and with the utmost
                                  attention to detail. <br />
                                  <br />
                                  One of the major challenges of this project
                                  was the size and scope of the excavation. Our
                                  team had to carefully plan and execute the
                                  excavation process to ensure that all fill was
                                  removed in an orderly and controlled manner.
                                  We utilized a variety of heavy machinery and
                                  excavation techniques to ensure that the site
                                  was excavated to the required specifications.
                                  In addition to the excavation, we were also
                                  responsible for the construction of various
                                  features within the wetland mitigation bank,
                                  such as channels, berms, and other water
                                  management structures. Overall, this project
                                  was an incredible opportunity for our team to
                                  showcase our expertise in site development and
                                  environmental restoration, and we are proud to
                                  have contributed to the creation of a valuable
                                  wetland habitat in the heart of Florida.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-sm-6">
                              <div className="ttm_single_image-wrapper text-left padding_top15">
                                <a
                                  data-lightbox="bottom-boars"
                                  href="/images/projects/boarshead ranch/DJI_0031.jpg"
                                >
                                  <img
                                    alt="single-img-10"
                                    className="img-fluid"
                                    src="/images/projects/boarshead ranch/DJI_0031.jpg"
                                  />
                                </a>
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <div className="ttm_single_image-wrapper text-left padding_top15">
                                <a
                                  data-lightbox="bottom-boars"
                                  href="/images/projects/boarshead ranch/DJI_0033.jpg"
                                >
                                  <img
                                    alt="single-img-11"
                                    className="img-fluid"
                                    src="/images/projects/boarshead ranch/DJI_0033.jpg"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default BoarsHeadRanch;
