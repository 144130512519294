import React from "react";
import Project from "../../../projects.json";

function ParcWesleyChapel() {
  const project = Project.projects.find((project) => project.id === 6);

  const currentUrl = window.location.href;
  const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    currentUrl
  )}`;
  const liUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
    currentUrl
  )}`;

  function shareToFacebook() {
    window.open(fbUrl, "_blank", "width=600,height=400");
  }

  function shareToLinkedIn() {
    window.open(liUrl, "_blank", "width=600,height=400");
  }
  return (
    <>
      <div className="ttm-page-title ttm-bg clearfix">
        <div className="ttm-row-wrapper-bg-layer" />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="ttm-page-title-row-inner">
                <div className="page-title-heading">
                  <h2 className="title">{project.title}</h2>
                </div>
                <div className="breadcrumb-wrapper">
                  <span>
                    <a href="/projects">&nbsp; Projects</a>
                  </span>
                  <span>Project Details</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="site-main">
        <section className="ttm-row project-single-section clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ttm-pf-single-content-wrapper">
                  <div className="ttm-pf-single-content-wrapper-innerbox">
                    <div className="row" style={{ justifyContent: "center" }}>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="featured-icon-box style10 without-icon">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5 className="mb-0 fs-17">Location:</h5>
                            </div>
                            <div className="featured-desc">
                              <p>{project.location}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="featured-icon-box style10 without-icon">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5 className="mb-0 fs-17">Date Started:</h5>
                            </div>
                            <div className="featured-desc">
                              <p>{project.started}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="featured-icon-box style10 without-icon">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5 className="mb-0 fs-17">Date Completed:</h5>
                            </div>
                            <div className="featured-desc">
                              <p>{project.ended}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="margin_bottom50 margin_top50 portfolio-single text-center position-relative">
                            <ul className="social-icons list-inline">
                              <li>
                                <a
                                  id="facebook-share-btn"
                                  onClick={shareToFacebook}
                                >
                                  <i className="fa fa-facebook" />
                                </a>
                              </li>
                              <li>
                                <a
                                  id="linkedin-share-btn"
                                  onClick={shareToLinkedIn}
                                >
                                  <i className="fa fa-linkedin" />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 col-lg-12">
                          <div className="slider ttm_pf_image-wrapper">
                            <div>
                              <a
                                data-lightbox="top-parc"
                                href="/images/projects/parc wesley chapel/DJI_0013.jpg"
                              >
                                <img
                                  alt="Slide 1"
                                  className="img-fluid"
                                  src="/images/projects/parc wesley chapel/DJI_0013.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="top-parc"
                                href="/images/projects/parc wesley chapel/DJI_0014.jpg"
                              >
                                <img
                                  alt="Slide 2"
                                  className="img-fluid"
                                  src="/images/projects/parc wesley chapel/DJI_0014.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="top-parc"
                                href="/images/projects/parc wesley chapel/DJI_0015.jpg"
                              >
                                <img
                                  alt="Slide 3"
                                  className="img-fluid"
                                  src="/images/projects/parc wesley chapel/DJI_0015.jpg"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="ttm-pf-single-content-area">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="margin_top40">
                                <h2>{project.title}</h2>
                                <p>
                                  The Parc at Wesley Chapel completed in early
                                  2020, following a project start date in late
                                  2018. The development required moving a total
                                  of 6,000 cubic yards of dirt to create the
                                  foundation for a four-building apartment
                                  complex and clubhouse. <br />
                                  <br />
                                  Our team installed a total of 42,000 square
                                  feet of high-quality sidewalk, ensuring
                                  residents have ample space for walking,
                                  jogging, and enjoying the beautifully
                                  landscaped surroundings. In addition, we
                                  installed 1,500 linear feet of waterline pipe,
                                  2,300 linear feet of fireline pipe, and 1,500
                                  linear feet of sanitary pipe to ensure the
                                  complex is fully equipped with essential
                                  infrastructure for comfortable living. <br />
                                  <br />
                                  One of the key features of The Parc at Wesley
                                  Chapel is the expansive asphalt surface, which
                                  provides residents with ample parking and a
                                  smooth surface for walking, jogging, and
                                  cycling. Our team took great care to ensure
                                  that this surface was not only functional, but
                                  also visually appealing, using high-quality
                                  materials and expert craftsmanship to create a
                                  truly beautiful environment. <br />
                                  <br />
                                  Our team was dedicated to making this project
                                  a success, and we are thrilled with the
                                  result. The Parc at Wesley Chapel is a
                                  testament to the high level of expertise and
                                  commitment that BRW brings to every project,
                                  no matter the size or complexity.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-sm-6">
                              <div className="ttm_single_image-wrapper text-left padding_top15">
                                <a
                                  data-lightbox="bottom-parc"
                                  href="/images/projects/parc wesley chapel/DJI_0020.jpg"
                                >
                                  <img
                                    alt="single-img-10"
                                    className="img-fluid"
                                    src="/images/projects/parc wesley chapel/DJI_0020.jpg"
                                  />
                                </a>
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <div className="ttm_single_image-wrapper text-left padding_top15">
                                <a
                                  data-lightbox="bottom-parc"
                                  href="/images/projects/parc wesley chapel/DJI_0022.jpg"
                                >
                                  <img
                                    alt="single-img-11"
                                    className="img-fluid"
                                    src="/images/projects/parc wesley chapel/DJI_0022.jpg"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default ParcWesleyChapel;
