import React from "react";

const JobModal = ({ id, title, description, applyLink }) => {
  return (
    <div className="modal fade" id={id} data-bs-backdrop="false">
      <div className="modal-backdrop-custom"></div>
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id={`${id}`}>
              {title}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div
            className="modal-body modal-scrollable"
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <div className="modal-footer">
            <a
              href="https://www.indeed.com/cmp/B.r.w.-Contracting,-Inc./jobs"
              className="btn btn-primary"
              target="_blank"
              rel="noopener noreferrer"
            >
              Apply
            </a>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobModal;
