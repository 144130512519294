import React from "react";
import Project from "../../../projects.json";

function DollarTreePhase2() {
  const project = Project.projects.find((project) => project.id === 12);

  const currentUrl = window.location.href;
  const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    currentUrl
  )}`;
  const liUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
    currentUrl
  )}`;

  function shareToFacebook() {
    window.open(fbUrl, "_blank", "width=600,height=400");
  }

  function shareToLinkedIn() {
    window.open(liUrl, "_blank", "width=600,height=400");
  }

  return (
    <>
      <div className="ttm-page-title ttm-bg clearfix">
        <div className="ttm-row-wrapper-bg-layer" />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="ttm-page-title-row-inner">
                <div className="page-title-heading">
                  <h2 className="title">{project.title}</h2>
                </div>
                <div className="breadcrumb-wrapper">
                  <span>
                    <a href="/projects">&nbsp; Projects</a>
                  </span>
                  <span>Project Details</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="site-main">
        <section className="ttm-row project-single-section clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ttm-pf-single-content-wrapper">
                  <div className="ttm-pf-single-content-wrapper-innerbox">
                    <div className="row" style={{ justifyContent: "center" }}>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="featured-icon-box style10 without-icon">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5 className="mb-0 fs-17">Location:</h5>
                            </div>
                            <div className="featured-desc">
                              <p>{project.location}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="featured-icon-box style10 without-icon">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5 className="mb-0 fs-17">Date Started:</h5>
                            </div>
                            <div className="featured-desc">
                              <p>{project.started}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="featured-icon-box style10 without-icon">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5 className="mb-0 fs-17">Date Completed:</h5>
                            </div>
                            <div className="featured-desc">
                              <p>{project.ended}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="margin_bottom50 margin_top50 portfolio-single text-center position-relative">
                            <ul className="social-icons list-inline">
                              <li>
                                <a
                                  id="facebook-share-btn"
                                  onClick={shareToFacebook}
                                >
                                  <i className="fa fa-facebook" />
                                </a>
                              </li>
                              <li>
                                <a
                                  id="linkedin-share-btn"
                                  onClick={shareToLinkedIn}
                                >
                                  <i className="fa fa-linkedin" />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 col-lg-12">
                          <div className="slider ttm_pf_image-wrapper">
                            <div>
                              <a
                                data-lightbox="top-dollarP2"
                                href="/images/projects/dollar tree phase 2/DJI_0003.jpg"
                              >
                                <img
                                  alt="Slide 1"
                                  className="img-fluid"
                                  src="/images/projects/dollar tree phase 2/DJI_0003.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="top-dollarP2"
                                href="/images/projects/dollar tree phase 2/DJI_0017.jpg"
                              >
                                <img
                                  alt="Slide 2"
                                  className="img-fluid"
                                  src="/images/projects/dollar tree phase 2/DJI_0017.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="top-dollarP2"
                                href="/images/projects/dollar tree phase 2/DJI_0020.jpg"
                              >
                                <img
                                  alt="Slide 3"
                                  className="img-fluid"
                                  src="/images/projects/dollar tree phase 2/DJI_0020.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="top-dollarP2"
                                href="/images/projects/dollar tree phase 2/DJI_0050.jpg"
                              >
                                <img
                                  alt="Slide 4"
                                  className="img-fluid"
                                  src="/images/projects/dollar tree phase 2/DJI_0050.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="top-dollarP2"
                                href="/images/projects/dollar tree phase 2/DJI_0057.jpg"
                              >
                                <img
                                  alt="Slide 5"
                                  className="img-fluid"
                                  src="/images/projects/dollar tree phase 2/DJI_0057.jpg"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="ttm-pf-single-content-area">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="margin_top40">
                                <h2>{project.title}</h2>
                                <p>
                                  The Dollar Tree project was no small feat,
                                  spanning across a sprawling ~100-acre site.
                                  Our team was tasked with a multitude of
                                  complex tasks to ensure that the site was
                                  prepped and ready to handle the demands of a
                                  bustling distribution center. One of our major
                                  tasks was mass grading approximately 350,000
                                  cubic yards of fill, requiring the use of
                                  heavy machinery and meticulous planning to
                                  ensure precise grading and minimal soil
                                  disturbance. With our expertise in grading and
                                  earthmoving, we were able to ensure the site
                                  was level and optimally prepared for future
                                  development. <br />
                                  <br />
                                  In addition to grading, our team also tackled
                                  the installation of essential infrastructure.
                                  This included storm sewer, sanitary sewer,
                                  potable water, and underground fire line. We
                                  understand the importance of reliable and
                                  efficient utility systems, and worked
                                  diligently to ensure that all systems were
                                  installed to meet the demands of the
                                  distribution center. Finally, we were
                                  responsible for the construction of the
                                  parking lot and concrete flatwork. We
                                  approached these tasks with a focus on safety,
                                  durability, and efficiency, ensuring that the
                                  finished product could withstand the heavy
                                  traffic and demands of the distribution
                                  center. Overall, this project was a testament
                                  to our team's expertise in site development
                                  and construction, and we were proud to deliver
                                  a site that met the needs of our client.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-sm-6">
                              <div className="ttm_single_image-wrapper text-left padding_top15">
                                <a
                                  data-lightbox="bottom-dollarP2"
                                  href="/images/projects/dollar tree phase 2/DJI_0030.jpg"
                                >
                                  <img
                                    alt="single-img-10"
                                    className="img-fluid"
                                    src="/images/projects/dollar tree phase 2/DJI_0030.jpg"
                                  />
                                </a>
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <div className="ttm_single_image-wrapper text-left padding_top15">
                                <a
                                  data-lightbox="bottom-dollarP2"
                                  href="/images/projects/dollar tree phase 2/DJI_0035.jpg"
                                >
                                  <img
                                    alt="single-img-11"
                                    className="img-fluid"
                                    src="/images/projects/dollar tree phase 2/DJI_0035.jpg"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default DollarTreePhase2;
