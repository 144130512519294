import React from "react";
import Project from "../../../projects.json";

function OakCreek() {
  const project = Project.projects.find((project) => project.id === 2);

  const currentUrl = window.location.href;
  const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    currentUrl
  )}`;
  const liUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
    currentUrl
  )}`;

  function shareToFacebook() {
    window.open(fbUrl, "_blank", "width=600,height=400");
  }

  function shareToLinkedIn() {
    window.open(liUrl, "_blank", "width=600,height=400");
  }

  return (
    <>
      <div className="ttm-page-title ttm-bg clearfix">
        <div className="ttm-row-wrapper-bg-layer" />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="ttm-page-title-row-inner">
                <div className="page-title-heading">
                  <h2 className="title">{project.title}</h2>
                </div>
                <div className="breadcrumb-wrapper">
                  <span>
                    <a href="/projects">&nbsp; Projects</a>
                  </span>
                  <span>Project Details</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="site-main">
        <section className="ttm-row project-single-section clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ttm-pf-single-content-wrapper">
                  <div className="ttm-pf-single-content-wrapper-innerbox">
                    <div className="row" style={{ justifyContent: "center" }}>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="featured-icon-box style10 without-icon">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5 className="mb-0 fs-17">Location:</h5>
                            </div>
                            <div className="featured-desc">
                              <p>{project.location}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="featured-icon-box style10 without-icon">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5 className="mb-0 fs-17">Date Started:</h5>
                            </div>
                            <div className="featured-desc">
                              <p>{project.started}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="featured-icon-box style10 without-icon">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5 className="mb-0 fs-17">Date Completed:</h5>
                            </div>
                            <div className="featured-desc">
                              <p>{project.ended}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="margin_bottom50 margin_top50 portfolio-single text-center position-relative">
                            <ul className="social-icons list-inline">
                              <li>
                                <a
                                  id="facebook-share-btn"
                                  onClick={shareToFacebook}
                                >
                                  <i className="fa fa-facebook" />
                                </a>
                              </li>
                              <li>
                                <a
                                  id="linkedin-share-btn"
                                  onClick={shareToLinkedIn}
                                >
                                  <i className="fa fa-linkedin" />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 col-lg-12">
                          <div className="slider ttm_pf_image-wrapper">
                            <div>
                              <a
                                data-lightbox="oak-top"
                                href="/images/projects/oak creek/DJI_0088.jpg"
                              >
                                <img
                                  alt="Slide 1"
                                  className="img-fluid"
                                  src="/images/projects/oak creek/DJI_0088.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="oak-top"
                                href="/images/projects/oak creek/DJI_0087.jpg"
                              >
                                <img
                                  alt="Slide 2"
                                  className="img-fluid"
                                  src="/images/projects/oak creek/DJI_0087.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="oak-top"
                                href="/images/projects/oak creek/DJI_0084.jpg"
                              >
                                <img
                                  alt="Slide 3"
                                  className="img-fluid"
                                  src="/images/projects/oak creek/DJI_0084.jpg"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="ttm-pf-single-content-area">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="margin_top40">
                                <h2>Oak Creek Parkway Business Center</h2>
                                <p>
                                  The Oak Creek Parkway Business Center project
                                  was a significant undertaking that required a
                                  wide range of construction expertise. The
                                  project's primary goal was to improve the
                                  overall infrastructure of the business center
                                  by constructing a new truck trailer parking
                                  lot, which was essential for the center's
                                  continued growth and development. <br />
                                  <br />
                                  To achieve this goal, the project involved
                                  several critical steps. First, the site
                                  grading was performed to prepare the area for
                                  the new parking lot. Next, the utilities were
                                  installed, ensuring that the parking lot had
                                  access to water, electricity, and other
                                  essential services. Then, the concrete
                                  installation was carried out, providing a
                                  solid base for the asphalt paving, which
                                  covered approximately 8200 square yards.
                                  Finally, the striping was applied to ensure
                                  that the parking lot was organized and easy to
                                  navigate. <br />
                                  <br />
                                  The decision to perform all the work in-house
                                  was a crucial factor in ensuring the timely
                                  completion of the project. By keeping all
                                  aspects of the project under one roof, the
                                  construction team was able to manage the
                                  project schedule effectively and complete all
                                  work on time. This approach allowed for
                                  efficient communication and collaboration
                                  between team members, ensuring that the
                                  project was completed to the highest possible
                                  standards of quality. <br />
                                  <br />
                                  Overall, the Oak Creek Parkway Business Center
                                  project was a resounding success, providing
                                  the business center with essential
                                  infrastructure improvements that will support
                                  its continued growth and development. The
                                  completion of this project highlights the
                                  importance of effective project management and
                                  collaboration in achieving successful
                                  construction outcomes.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-sm-6">
                              <div className="ttm_single_image-wrapper text-left padding_top15">
                                <a
                                  data-lightbox="oak-bottom"
                                  href="/images/projects/oak creek/DJI_0090.jpg"
                                >
                                  <img
                                    alt="single-img-10"
                                    className="img-fluid"
                                    src="/images/projects/oak creek/DJI_0090.jpg"
                                  />
                                </a>
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <div className="ttm_single_image-wrapper text-left padding_top15">
                                <a
                                  data-lightbox="oak-bottom"
                                  href="/images/projects/oak creek/DJI_0091.jpg"
                                >
                                  <img
                                    alt="single-img-11"
                                    className="img-fluid"
                                    src="/images/projects/oak creek/DJI_0091.jpg"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default OakCreek;
