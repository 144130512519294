/* global $ */

import React, { useEffect, useState } from "react";
import projects from "../../projects.json";

const ProjectCard = () => {
  const [isotopeInitialized, setIsotopeInitialized] = useState(false);

  useEffect(() => {
    if ($().isotope && !isotopeInitialized) {
      var $container = $(".isotope-project");

      $container.imagesLoaded(function () {
        $container.isotope({
          itemSelector: ".grid-item",
          transitionDuration: "1s",
          filter: ".feat",
        });

        $(".portfolio-filter li:first-child").addClass("active");

        $(".portfolio-filter li").on("click", function () {
          var $this = $(this);
          if (!$this.hasClass("active")) {
            var selector = $this.find("a").attr("data-filter");

            $(".portfolio-filter li").removeClass("active");

            $this.addClass("active");

            selector = selector || "*";

            $container.isotope({ filter: selector });
          }

          return false;
        });
        setIsotopeInitialized(true);
      });
    }
  }, [isotopeInitialized]);

  return (
    <>
      {projects.projects.map((project) => (
        <div
          className={`col-lg-4 col-md-4 col-sm-6 grid-item ${
            project.categories ? project.categories.join(" ") : ""
          }`}
          key={project.id}
        >
          <div className="featured-imagebox featured-imagebox-portfolio style1">
            <div className="ttm-box-view-overlay ttm-portfolio-box-view-overlay">
              <div className="featured-thumbnail">
                <a href={project.link} className="ttm_link">
                  <img className="img-fluid" src={project.cover} alt="" />
                </a>
              </div>
              <div className="ttm-media-link">
                <a href={project.link} className="ttm_link">
                  <i className="ti ti-plus" />
                </a>
              </div>
            </div>
            <div className="featured-content featured-content-portfolio">
              <div className="featured-title">
                <h3>
                  <a href={project.link}>{project.title}</a>
                </h3>
              </div>
              <span className="category">
                <span>
                  <i>{project.location}</i>
                </span>
                <br />
                <span>{project.ended}</span>
              </span>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ProjectCard;
