import React from "react";
import JobModal from "./jobModal";
import jobsData from "../../JobOpenings.json";

const Jobs = () => {
  return (
    <div className="container">
      {jobsData.JobOpenings.map((job) => (
        <div key={job.id}>
          <a
            className="btn-link"
            data-bs-toggle="modal"
            data-bs-target={`#${job.id}`}
            href="#"
          >
            &bull; {job.title}
          </a>
          <br />
          <JobModal
            key={job.id}
            id={job.id}
            title={job.title}
            description={job.description}
            applyLink={job.applyLink}
          />
        </div>
      ))}
    </div>
  );
};

export default Jobs;
