import Logo from "../../images/brwlogo.avif";

function Header() {
  return (
    <>
      <header id="masthead" className="header ttm-header-style-01">
        <div className="top_bar ttm-bgcolor-darkgrey clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="top_bar_contact_item">
                      <div className="top_bar_icon">
                        <i className="fa fa-location-dot" />
                      </div>
                      <div className="top_bar_content">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://www.google.com/maps/dir//brw+contracting/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x88c2bbfa4c781b39:0xdafff6d9ce08c51b?sa=X&ved=2ahUKEwiO5uTz9fT8AhW-TTABHXPFABoQ9Rd6BAhIEAU"
                        >
                          2522 Hunt Rd, Land O' Lakes, FL 34638
                        </a>
                      </div>
                    </div>
                    <div className="top_bar_contact_item">
                      <div className="top_bar_icon">
                        <i className="fa fa-envelope" />
                      </div>
                      <div className="top_bar_content">
                        {" "}
                        <a href="mailto:bidrequests@brwcontracting.org">
                          bidrequests@brwcontracting.org
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="contact-info">
                      <div className="top_bar_contact_item top_bar_social ml-auto">
                        <ul className="social-icons d-flex">
                          <li>
                            <a
                              className="ttm-social-instagram"
                              target="_blank"
                              href="https://www.instagram.com/brwcontractinginc"
                              rel="noreferrer"
                              aria-label="instagram"
                            >
                              <i className="fa fa-instagram" />
                            </a>
                          </li>
                          <li>
                            <a
                              className="ttm-social-youtube"
                              target="_blank"
                              href="https://www.youtube.com/channel/UCZK7Sk8HvNqLYKcdx4w2xrQ"
                              rel="noreferrer"
                              aria-label="youtube"
                            >
                              <i className="fa fa-youtube" />
                            </a>
                          </li>
                          <li>
                            <a
                              className="ttm-social-facebook"
                              target="_blank"
                              href="https://www.facebook.com/brwcontracting"
                              rel="noreferrer"
                              aria-label="facebook"
                            >
                              <i className=" fa fa-facebook-f" />
                            </a>
                          </li>
                          <li>
                            <a
                              className="ttm-social-linkedin"
                              target="_blank"
                              href="https://www.linkedin.com/company/brwcontracting/"
                              rel="noreferrer"
                              aria-label="linkedin"
                            >
                              <i className="fa fa-linkedin" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="top_bar_contact_item ttm-highlight-right">
                        <div className="top_bar_icon">
                          <i className="fa fa-phone" />
                        </div>
                        <div className="top_bar_content">
                          {" "}
                          Call Us Anytime:&nbsp;
                          <strong>
                            <a href="tel:18139965882">1 (813) 996-5882</a>
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="site-navigation">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <nav className="navbar navbar-expand-lg navbar-dark p-0">
                  <div className="logo-area">
                    <div className="row align-items-center">
                      <div className="logo col-lg-3 text-center text-lg-left mb-md-5 mb-lg-0">
                        <a className="d-block" href="/">
                          <img
                            loading="lazy"
                            src="https://brw-fl.com/static/media/brwlogo.adbdd795cc5b75673998.avif"
                            alt="Constra"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbar-collapse"
                    aria-controls="navbar-collapse"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>

                  <div
                    id="navbar-collapse"
                    className="collapse navbar-collapse"
                  >
                    <ul className="navbar-nav ms-auto">
                      <li className="nav-item">
                        <a className="nav-link" href="/">
                          Home
                        </a>
                      </li>

                      <li className="nav-item dropdown">
                        <a
                          href="#"
                          className="nav-link dropdown-toggle"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          About <i className="fa fa-angle-down"></i>
                        </a>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          <li>
                            <a className="dropdown-item" href="/about">
                              About Us
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="/team">
                              Our Team
                            </a>
                          </li>
                        </ul>
                      </li>

                      <li className="nav-item dropdown">
                        <a
                          href="#"
                          className="nav-link dropdown-toggle"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Services <i className="fa fa-angle-down"></i>
                        </a>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          <li>
                            <a className="dropdown-item" href="/services">
                              Our Services
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="/earthwork">
                              Earthwork
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="/undergroundUtilities"
                            >
                              Underground Utilities
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="/segmentedRetainingWalls"
                            >
                              Segmented Block Retaining Walls
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="/concrete">
                              Concrete
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="/roadwayConstruction"
                            >
                              Roadway Construction
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="/pavingStriping">
                              Paving & Striping
                            </a>
                          </li>
                        </ul>
                      </li>

                      <li className="nav-item">
                        <a className="nav-link" href="/projects">
                          Projects
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/careers">
                          Careers
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/contact">
                          Contact
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
