import React from "react";
import Project from "../../../projects.json";

function soverieignTownCenter() {
  const project = Project.projects.find((project) => project.id === 26);

  const currentUrl = window.location.href;
  const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    currentUrl
  )}`;
  const liUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
    currentUrl
  )}`;

  function shareToFacebook() {
    window.open(fbUrl, "_blank", "width=600,height=400");
  }

  function shareToLinkedIn() {
    window.open(liUrl, "_blank", "width=600,height=400");
  }

  return (
    <>
      <div className="ttm-page-title ttm-bg clearfix">
        <div className="ttm-row-wrapper-bg-layer" />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="ttm-page-title-row-inner">
                <div className="page-title-heading">
                  <h2 className="title">{project.title}</h2>
                </div>
                <div className="breadcrumb-wrapper">
                  <span>
                    <a href="/projects">&nbsp; Projects</a>
                  </span>
                  <span>Project Details</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="site-main">
        <section className="ttm-row project-single-section clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ttm-pf-single-content-wrapper">
                  <div className="ttm-pf-single-content-wrapper-innerbox">
                    <div className="row" style={{ justifyContent: "center" }}>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="featured-icon-box style10 without-icon">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5 className="mb-0 fs-17">Location:</h5>
                            </div>
                            <div className="featured-desc">
                              <p>{project.location}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="featured-icon-box style10 without-icon">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5 className="mb-0 fs-17">Date Started:</h5>
                            </div>
                            <div className="featured-desc">
                              <p>{project.started}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="featured-icon-box style10 without-icon">
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5 className="mb-0 fs-17">Date Completed:</h5>
                            </div>
                            <div className="featured-desc">
                              <p>{project.ended}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="margin_bottom50 margin_top50 portfolio-single text-center position-relative">
                            <ul className="social-icons list-inline">
                              <li>
                                <a
                                  id="facebook-share-btn"
                                  onClick={shareToFacebook}
                                >
                                  <i className="fa fa-facebook" />
                                </a>
                              </li>
                              <li>
                                <a
                                  id="linkedin-share-btn"
                                  onClick={shareToLinkedIn}
                                >
                                  <i className="fa fa-linkedin" />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 col-lg-12">
                          <div className="slider ttm_pf_image-wrapper">
                            <div>
                              <a
                                data-lightbox="sovereign-top"
                                href="/images/projects/sovereign town center/DJI_0022.jpg"
                              >
                                <img
                                  alt="Slide 1"
                                  className="img-fluid"
                                  src="/images/projects/sovereign town center/DJI_0022.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="sovereign-top"
                                href="/images/projects/sovereign town center/DJI_0023.jpg"
                              >
                                <img
                                  alt="Slide 2"
                                  className="img-fluid"
                                  src="/images/projects/sovereign town center/DJI_0023.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="sovereign-top"
                                href="/images/projects/sovereign town center/DJI_0024.jpg"
                              >
                                <img
                                  alt="Slide 3"
                                  className="img-fluid"
                                  src="/images/projects/sovereign town center/DJI_0024.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="sovereign-top"
                                href="/images/projects/sovereign town center/DJI_0025.jpg"
                              >
                                <img
                                  alt="Slide 4"
                                  className="img-fluid"
                                  src="/images/projects/sovereign town center/DJI_0025.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="sovereign-top"
                                href="/images/projects/sovereign town center/DJI_0026.jpg"
                              >
                                <img
                                  alt="Slide 5"
                                  className="img-fluid"
                                  src="/images/projects/sovereign town center/DJI_0026.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="sovereign-top"
                                href="/images/projects/sovereign town center/DJI_0027.jpg"
                              >
                                <img
                                  alt="Slide 6"
                                  className="img-fluid"
                                  src="/images/projects/sovereign town center/DJI_0027.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="sovereign-top"
                                href="/images/projects/sovereign town center/DJI_0032.jpg"
                              >
                                <img
                                  alt="Slide 7"
                                  className="img-fluid"
                                  src="/images/projects/sovereign town center/DJI_0032.jpg"
                                />
                              </a>
                            </div>
                            <div>
                              <a
                                data-lightbox="sovereign-top"
                                href="/images/projects/sovereign town center/DJI_0035.jpg"
                              >
                                <img
                                  alt="Slide 8"
                                  className="img-fluid"
                                  src="/images/projects/sovereign town center/DJI_0035.jpg"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="ttm-pf-single-content-area">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="margin_top40">
                                <h2>{project.title}</h2>
                                <p>
                                  In partnership with First Florida
                                  Construction, we're currently engaged in an
                                  exciting venture - the construction of upscale
                                  Sovereign apartments in Davenport. <br />
                                  <br />
                                  Our comprehensive sitework package involves
                                  multiple facets. From initial clearing,
                                  grubbing, and controlled demolition to
                                  crafting precise building pads. Our role also
                                  extends to intricate storm and utility
                                  installations, which include a lift station
                                  for efficient waste management. Enhancing
                                  accessibility and functionality, we're
                                  widening roads and ensuring robust base and
                                  subbase installations before paving, striping,
                                  and installing essential signage. To add a
                                  touch of sophistication, we're also working on
                                  concrete flatwork and curbing. <br />
                                  <br />
                                  Stay tuned as Sovereign at Town Center
                                  reshapes luxury living in Davenport,
                                  redefining the essence of upscale comfort and
                                  convenience.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-sm-6">
                              <div className="ttm_single_image-wrapper text-left padding_top15">
                                <a
                                  data-lightbox="sovereign-bottom"
                                  href="/images/projects/sovereign town center/DJI_0036.jpg"
                                >
                                  <img
                                    alt="single-img-10"
                                    className="img-fluid"
                                    src="/images/projects/sovereign town center/DJI_0036.jpg"
                                  />
                                </a>
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <div className="ttm_single_image-wrapper text-left padding_top15">
                                <a
                                  data-lightbox="sovereign-bottom"
                                  href="/images/projects/sovereign town center/DJI_0041.jpg"
                                >
                                  <img
                                    alt="single-img-11"
                                    className="img-fluid"
                                    src="/images/projects/sovereign town center/DJI_0041.jpg"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default soverieignTownCenter;
